import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import buttonStyles from "./button.module.scss"
const Button = ({ children, to, brown = false }) => {
  return (
    <Link className={brown ? buttonStyles.btnBrown : buttonStyles.btn} to={to}>
      {children}
    </Link>
  )
}

Button.propType = {
  route: PropTypes.string,
}

Button.defaultProps = {
  route: "",
}

Button.propTypes = {
  className: PropTypes.string,
}

Button.defaultProps = {
  className: ``,
}

export default Button
