import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/UI/Title.js"
import Button from "../components/UI/Button/button.js"
import heroShapeLeft from "../assets/images/hero_shape_left.svg"
import heroShapeRight from "../assets/images/hero_shape_right.svg"
import heroShapeTop from "../assets/images/hero_shape_top.svg"
const NotFoundPage = () => (
  <Layout displayFooter="false">
    <SEO title="404: Not found" />
    <section className="error">
      <img src={heroShapeLeft} alt="" />
      <img src={heroShapeRight} alt="" />
      <img src={heroShapeTop} alt="" />
      <div className="wrapper">
        <Title type="h1">404</Title>
        <p>Oops! Something went wrong...</p>

        <Button to="/" className="btn--goToHome">
          Back to homepage
        </Button>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
